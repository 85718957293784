import { useSelector } from 'react-redux';
import LoginPage from './Login';
import NotificationPage from './Notification';
import RequestReviewPage from './RequestReview';
import ReviewInProgressPage from './ReviewInProgress';
import TwoFactorPage from './TwoFactor';
import VerificationTemplatePage from './VerificationTemplate';

const MainV1 = () => {
    const screen = useSelector((state) => state.navigation.screen);

    switch (screen) {
        case "login":
            return <LoginPage />;
        case "notification":
            return <NotificationPage />;
        case "twoFactor":
            return <TwoFactorPage />;
        case "verificationTemplate":
            return <VerificationTemplatePage />;
        case "requestReview":
            return <RequestReviewPage />;
        case "reviewInProgress":
            return <ReviewInProgressPage />;
        default:
            return <LoginPage />;
    }
}

export default MainV1;