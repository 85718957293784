import React from "react";
import { ImSpinner8 } from "react-icons/im";

const Button = ({
    type = "button",
    disabled = false,
    loading = false,
    fullWidth = false,
    children,
    className = "",
    ...props
}) => {
    return (
        <button
            type={type}
            disabled={disabled || loading}
            className={`${
                fullWidth ? "w-full" : ""
            } outline-none border-none flex items-center justify-center px-4 py-3 text-sm font-medium text-white bg-blue-600 rounded-md transition-colors duration-200 ${
                loading || disabled
                    ? "bg-blue-400 cursor-not-allowed"
                    : "hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
            } ${className}`}
            {...props}
        >
            {loading ? (
                <span className="flex items-center space-x-2">
                    <ImSpinner8 className="animate-spin w-5 h-5 text-white" />
                </span>
            ) : (
                children
            )}
        </button>
    );
};

export default Button;
