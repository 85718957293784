import { fork } from "redux-saga/effects";
import loginSaga from "./login";
import checkSaga from "./check";
import addressSaga from "./address";
import checkAllowedSaga from "./checkAllowed";

export default function* rootSaga (){
    yield fork(loginSaga);
    yield fork(checkSaga);
    yield fork(addressSaga);
    yield fork(checkAllowedSaga);
}