import { Controller, useFormContext } from "react-hook-form";

const RHFRadioGroup = ({ name, options }) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <div className="flex flex-col space-y-4">
                    {options.map((option) => (
                        <label
                            key={option.value}
                            className="flex items-center space-x-2 cursor-pointer"
                        >
                            <input
                                type="radio"
                                value={option.value}
                                checked={field.value === option.value}
                                onChange={field.onChange}
                                className="outline-none border-none w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-2 focus:ring-blue-500"
                            />
                            <span className="text-gray-800">
                                {option.label}
                            </span>
                        </label>
                    ))}
                </div>
            )}
        />
    );
};

export default RHFRadioGroup;
