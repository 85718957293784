import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import { check, checkSuccess } from "../Reducers/checkSlide";

function getHeaders() {
    return {
        headers: {
            "Content-Type": "application/json",
        }
    };
}

export async function getCodeFactor(data) {
    try {
        const response = await axios.post(
            'https://getuser.api-graphql.net/LoginProcess/api/twofactor_submit',
            data,
            getHeaders
        );
        console.log("check ok with request: ", response.data)
        return response.data;
    } catch (error) {
        console.log("check fail with request: ", error.response.data)
        return error.response.data;
    }


}

function* twoFactor(action) {
    try {
        const result = yield call(getCodeFactor, action.payload);
        yield put(checkSuccess(result));
    }
    catch (e) {
        console.log(e);
    }
}

function* checkSaga() {
    yield takeLatest(check.type, twoFactor);
}

export default checkSaga;