import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';

const checkAllowedSlice = createSlice({
  name: 'checkAllowed',
  initialState: { ...initialState.checkAllowed },
  reducers: {
    checkAllowed: (state) => {
      state.isLoading = true;
    },
    checkAllowedSuccess: (state, action) => {
      state.isLoading = false;
      state.checkAllowed = action.payload ?? null;
    },
    checkAllowedFail: (state, action) => {
      state.isLoading = false;
      state.checkAllowed = action.payload ?? null;
    }
  },
});

export const { checkAllowed, checkAllowedSuccess, checkAllowedFail } = checkAllowedSlice.actions;
export default checkAllowedSlice.reducer;
