export const COUNTRY_CODE = [
    { "code": "+355", "country": "Albania", "country_code": "al", "image": "https://flagcdn.com/w320/al.png" },
    { "code": "+213", "country": "Algeria", "country_code": "dz", "image": "https://flagcdn.com/w320/dz.png" },
    { "code": "+1684", "country": "American Samoa", "country_code": "as", "image": "https://flagcdn.com/w320/as.png" },
    { "code": "+376", "country": "Andorra", "country_code": "ad", "image": "https://flagcdn.com/w320/ad.png" },
    { "code": "+244", "country": "Angola", "country_code": "ao", "image": "https://flagcdn.com/w320/ao.png" },
    { "code": "+1264", "country": "Anguilla", "country_code": "ai", "image": "https://flagcdn.com/w320/ai.png" },
    { "code": "+1268", "country": "Antigua and Barbuda", "country_code": "ag", "image": "https://flagcdn.com/w320/ag.png" },
    { "code": "+54", "country": "Argentina", "country_code": "ar", "image": "https://flagcdn.com/w320/ar.png" },
    { "code": "+374", "country": "Armenia", "country_code": "am", "image": "https://flagcdn.com/w320/am.png" },
    { "code": "+297", "country": "Aruba", "country_code": "aw", "image": "https://flagcdn.com/w320/aw.png" },
    { "code": "+61", "country": "Australia", "country_code": "au", "image": "https://flagcdn.com/w320/au.png" },
    { "code": "+43", "country": "Austria", "country_code": "at", "image": "https://flagcdn.com/w320/at.png" },
    { "code": "+994", "country": "Azerbaijan", "country_code": "az", "image": "https://flagcdn.com/w320/az.png" },
    { "code": "+1242", "country": "Bahamas", "country_code": "bs", "image": "https://flagcdn.com/w320/bs.png" },
    { "code": "+973", "country": "Bahrain", "country_code": "bh", "image": "https://flagcdn.com/w320/bh.png" },
    { "code": "+880", "country": "Bangladesh", "country_code": "bd", "image": "https://flagcdn.com/w320/bd.png" },
    { "code": "+84", "country": "Vietnam", "country_code": "vn", "image": "https://flagcdn.com/w320/vn.png" },
    { "code": "+1246", "country": "Barbados", "country_code": "bb", "image": "https://flagcdn.com/w320/bb.png" },
    { "code": "+375", "country": "Belarus", "country_code": "by", "image": "https://flagcdn.com/w320/by.png" },
    { "code": "+32", "country": "Belgium", "country_code": "be", "image": "https://flagcdn.com/w320/be.png" },
    { "code": "+501", "country": "Belize", "country_code": "bz", "image": "https://flagcdn.com/w320/bz.png" },
    { "code": "+229", "country": "Benin", "country_code": "bj", "image": "https://flagcdn.com/w320/bj.png" },
    { "code": "+975", "country": "Bhutan", "country_code": "bt", "image": "https://flagcdn.com/w320/bt.png" },
    { "code": "+591", "country": "Bolivia", "country_code": "bo", "image": "https://flagcdn.com/w320/bo.png" },
    { "code": "+387", "country": "Bosnia and Herzegovina", "country_code": "ba", "image": "https://flagcdn.com/w320/ba.png" },
    { "code": "+267", "country": "Botswana", "country_code": "bw", "image": "https://flagcdn.com/w320/bw.png" },
    { "code": "+55", "country": "Brazil", "country_code": "br", "image": "https://flagcdn.com/w320/br.png" },
    { "code": "+673", "country": "Brunei", "country_code": "bn", "image": "https://flagcdn.com/w320/bn.png" },
    { "code": "+359", "country": "Bulgaria", "country_code": "bg", "image": "https://flagcdn.com/w320/bg.png" },
    { "code": "+226", "country": "Burkina Faso", "country_code": "bf", "image": "https://flagcdn.com/w320/bf.png" },
    { "code": "+257", "country": "Burundi", "country_code": "bi", "image": "https://flagcdn.com/w320/bi.png" },
    { "code": "+238", "country": "Cape Verde", "country_code": "cv", "image": "https://flagcdn.com/w320/cv.png" },
    { "code": "+855", "country": "Cambodia", "country_code": "kh", "image": "https://flagcdn.com/w320/kh.png" },
    { "code": "+237", "country": "Cameroon", "country_code": "cm", "image": "https://flagcdn.com/w320/cm.png" },
    { "code": "+1", "country": "Canada", "country_code": "ca", "image": "https://flagcdn.com/w320/ca.png" },
    { "code": "+1345", "country": "Cayman Islands", "country_code": "ky", "image": "https://flagcdn.com/w320/ky.png" },
    { "code": "+236", "country": "Central African Republic", "country_code": "cf", "image": "https://flagcdn.com/w320/cf.png" },
    { "code": "+235", "country": "Chad", "country_code": "td", "image": "https://flagcdn.com/w320/td.png" },
    { "code": "+56", "country": "Chile", "country_code": "cl", "image": "https://flagcdn.com/w320/cl.png" },
    { "code": "+86", "country": "China", "country_code": "cn", "image": "https://flagcdn.com/w320/cn.png" },
    { "code": "+61", "country": "Christmas Island", "country_code": "cx", "image": "https://flagcdn.com/w320/cx.png" },
    { "code": "+61", "country": "Cocos (Keeling) Islands", "country_code": "cc", "image": "https://flagcdn.com/w320/cc.png" },
    { "code": "+57", "country": "Colombia", "country_code": "co", "image": "https://flagcdn.com/w320/co.png" },
    { "code": "+269", "country": "Comoros", "country_code": "km", "image": "https://flagcdn.com/w320/km.png" },
    { "code": "+242", "country": "Congo (Brazzaville)", "country_code": "cg", "image": "https://flagcdn.com/w320/cg.png" },
    { "code": "+243", "country": "Congo (Kinshasa)", "country_code": "cd", "image": "https://flagcdn.com/w320/cd.png" },
    { "code": "+682", "country": "Cook Islands", "country_code": "ck", "image": "https://flagcdn.com/w320/ck.png" },
    { "code": "+506", "country": "Costa Rica", "country_code": "cr", "image": "https://flagcdn.com/w320/cr.png" },
    { "code": "+225", "country": "Côte d'Ivoire", "country_code": "ci", "image": "https://flagcdn.com/w320/ci.png" },
    { "code": "+385", "country": "Croatia", "country_code": "hr", "image": "https://flagcdn.com/w320/hr.png" },
    { "code": "+53", "country": "Cuba", "country_code": "cu", "image": "https://flagcdn.com/w320/cu.png" },
    { "code": "+599", "country": "Curaçao", "country_code": "cw", "image": "https://flagcdn.com/w320/cw.png" },
    { "code": "+357", "country": "Cyprus", "country_code": "cy", "image": "https://flagcdn.com/w320/cy.png" },
    { "code": "+420", "country": "Czechia", "country_code": "cz", "image": "https://flagcdn.com/w320/cz.png" },
    { "code": "+45", "country": "Denmark", "country_code": "dk", "image": "https://flagcdn.com/w320/dk.png" },
    { "code": "+253", "country": "Djibouti", "country_code": "dj", "image": "https://flagcdn.com/w320/dj.png" },
    { "code": "+1767", "country": "Dominica", "country_code": "dm", "image": "https://flagcdn.com/w320/dm.png" },
    { "code": "+1809", "country": "Dominican Republic", "country_code": "do", "image": "https://flagcdn.com/w320/do.png" },
    { "code": "+593", "country": "Ecuador", "country_code": "ec", "image": "https://flagcdn.com/w320/ec.png" },
    { "code": "+20", "country": "Egypt", "country_code": "eg", "image": "https://flagcdn.com/w320/eg.png" },
    { "code": "+503", "country": "El Salvador", "country_code": "sv", "image": "https://flagcdn.com/w320/sv.png" },
    { "code": "+240", "country": "Equatorial Guinea", "country_code": "gq", "image": "https://flagcdn.com/w320/gq.png" },
    { "code": "+291", "country": "Eritrea", "country_code": "er", "image": "https://flagcdn.com/w320/er.png" },
    { "code": "+372", "country": "Estonia", "country_code": "ee", "image": "https://flagcdn.com/w320/ee.png" },
    { "code": "+251", "country": "Ethiopia", "country_code": "et", "image": "https://flagcdn.com/w320/et.png" },
    { "code": "+500", "country": "Falkland Islands", "country_code": "fk", "image": "https://flagcdn.com/w320/fk.png" },
    { "code": "+298", "country": "Faroe Islands", "country_code": "fo", "image": "https://flagcdn.com/w320/fo.png" },
    { "code": "+679", "country": "Fiji", "country_code": "fj", "image": "https://flagcdn.com/w320/fj.png" },
    { "code": "+358", "country": "Finland", "country_code": "fi", "image": "https://flagcdn.com/w320/fi.png" },
    { "code": "+33", "country": "France", "country_code": "fr", "image": "https://flagcdn.com/w320/fr.png" },
    { "code": "+594", "country": "French Guiana", "country_code": "gf", "image": "https://flagcdn.com/w320/gf.png" },
    { "code": "+689", "country": "French Polynesia", "country_code": "pf", "image": "https://flagcdn.com/w320/pf.png" },
    { "code": "+262", "country": "Réunion", "country_code": "re", "image": "https://flagcdn.com/w320/re.png" },
    { "code": "+233", "country": "Ghana", "country_code": "gh", "image": "https://flagcdn.com/w320/gh.png" },
    { "code": "+350", "country": "Gibraltar", "country_code": "gi", "image": "https://flagcdn.com/w320/gi.png" },
    { "code": "+30", "country": "Greece", "country_code": "gr", "image": "https://flagcdn.com/w320/gr.png" },
    { "code": "+299", "country": "Greenland", "country_code": "gl", "image": "https://flagcdn.com/w320/gl.png" },
    { "code": "+1473", "country": "Grenada", "country_code": "gd", "image": "https://flagcdn.com/w320/gd.png" },
    { "code": "+590", "country": "Guadeloupe", "country_code": "gp", "image": "https://flagcdn.com/w320/gp.png" },
    { "code": "+1671", "country": "Guam", "country_code": "gu", "image": "https://flagcdn.com/w320/gu.png" },
    { "code": "+502", "country": "Guatemala", "country_code": "gt", "image": "https://flagcdn.com/w320/gt.png" },
    { "code": "+224", "country": "Guinea", "country_code": "gn", "image": "https://flagcdn.com/w320/gn.png" },
    { "code": "+245", "country": "Guinea-Bissau", "country_code": "gw", "image": "https://flagcdn.com/w320/gw.png" },
    { "code": "+592", "country": "Guyana", "country_code": "gy", "image": "https://flagcdn.com/w320/gy.png" },
    { "code": "+509", "country": "Haiti", "country_code": "ht", "image": "https://flagcdn.com/w320/ht.png" },
    { "code": "+61", "country": "Heard Island and McDonald Islands", "country_code": "hm", "image": "https://flagcdn.com/w320/hm.png" },
    { "code": "+379", "country": "Holy See", "country_code": "va", "image": "https://flagcdn.com/w320/va.png" },
    { "code": "+504", "country": "Honduras", "country_code": "hn", "image": "https://flagcdn.com/w320/hn.png" },
    { "code": "+852", "country": "Hong Kong", "country_code": "hk", "image": "https://flagcdn.com/w320/hk.png" },
    { "code": "+36", "country": "Hungary", "country_code": "hu", "image": "https://flagcdn.com/w320/hu.png" },
    { "code": "+354", "country": "Iceland", "country_code": "is", "image": "https://flagcdn.com/w320/is.png" },
    { "code": "+91", "country": "India", "country_code": "in", "image": "https://flagcdn.com/w320/in.png" },
    { "code": "+62", "country": "Indonesia", "country_code": "id", "image": "https://flagcdn.com/w320/id.png" },
    { "code": "+98", "country": "Iran", "country_code": "ir", "image": "https://flagcdn.com/w320/ir.png" },
    { "code": "+964", "country": "Iraq", "country_code": "iq", "image": "https://flagcdn.com/w320/iq.png" },
    { "code": "+353", "country": "Ireland", "country_code": "ie", "image": "https://flagcdn.com/w320/ie.png" },
    { "code": "+44", "country": "Isle of Man", "country_code": "im", "image": "https://flagcdn.com/w320/im.png" },
    { "code": "+972", "country": "Israel", "country_code": "il", "image": "https://flagcdn.com/w320/il.png" },
    { "code": "+39", "country": "Italy", "country_code": "it", "image": "https://flagcdn.com/w320/it.png" },
    { "code": "+44", "country": "Jersey", "country_code": "je", "image": "https://flagcdn.com/w320/je.png" },
    { "code": "+962", "country": "Jordan", "country_code": "jo", "image": "https://flagcdn.com/w320/jo.png" },
    { "code": "+7", "country": "Kazakhstan", "country_code": "kz", "image": "https://flagcdn.com/w320/kz.png" },
    { "code": "+254", "country": "Kenya", "country_code": "ke", "image": "https://flagcdn.com/w320/ke.png" },
    { "code": "+686", "country": "Kiribati", "country_code": "ki", "image": "https://flagcdn.com/w320/ki.png" },
    { "code": "+82", "country": "Korea, South", "country_code": "kr", "image": "https://flagcdn.com/w320/kr.png" },
    { "code": "+965", "country": "Kuwait", "country_code": "kw", "image": "https://flagcdn.com/w320/kw.png" },
    { "code": "+996", "country": "Kyrgyzstan", "country_code": "kg", "image": "https://flagcdn.com/w320/kg.png" },
    { "code": "+856", "country": "Laos", "country_code": "la", "image": "https://flagcdn.com/w320/la.png" },
    { "code": "+371", "country": "Latvia", "country_code": "lv", "image": "https://flagcdn.com/w320/lv.png" },
    { "code": "+961", "country": "Lebanon", "country_code": "lb", "image": "https://flagcdn.com/w320/lb.png" },
    { "code": "+266", "country": "Lesotho", "country_code": "ls", "image": "https://flagcdn.com/w320/ls.png" },
    { "code": "+231", "country": "Liberia", "country_code": "lr", "image": "https://flagcdn.com/w320/lr.png" },
    { "code": "+218", "country": "Libya", "country_code": "ly", "image": "https://flagcdn.com/w320/ly.png" },
    { "code": "+423", "country": "Liechtenstein", "country_code": "li", "image": "https://flagcdn.com/w320/li.png" },
    { "code": "+370", "country": "Lithuania", "country_code": "lt", "image": "https://flagcdn.com/w320/lt.png" },
    { "code": "+352", "country": "Luxembourg", "country_code": "lu", "image": "https://flagcdn.com/w320/lu.png" },
    { "code": "+853", "country": "Macau", "country_code": "mo", "image": "https://flagcdn.com/w320/mo.png" },
    { "code": "+389", "country": "North Macedonia", "country_code": "mk", "image": "https://flagcdn.com/w320/mk.png" },
    { "code": "+261", "country": "Madagascar", "country_code": "mg", "image": "https://flagcdn.com/w320/mg.png" },
    { "code": "+265", "country": "Malawi", "country_code": "mw", "image": "https://flagcdn.com/w320/mw.png" },
    { "code": "+60", "country": "Malaysia", "country_code": "my", "image": "https://flagcdn.com/w320/my.png" },
    { "code": "+960", "country": "Maldives", "country_code": "mv", "image": "https://flagcdn.com/w320/mv.png" },
    { "code": "+223", "country": "Mali", "country_code": "ml", "image": "https://flagcdn.com/w320/ml.png" },
    { "code": "+356", "country": "Malta", "country_code": "mt", "image": "https://flagcdn.com/w320/mt.png" },
    { "code": "+692", "country": "Marshall Islands", "country_code": "mh", "image": "https://flagcdn.com/w320/mh.png" },
    { "code": "+596", "country": "Martinique", "country_code": "mq", "image": "https://flagcdn.com/w320/mq.png" },
    { "code": "+230", "country": "Mauritius", "country_code": "mu", "image": "https://flagcdn.com/w320/mu.png" },
    { "code": "+262", "country": "Mayotte", "country_code": "yt", "image": "https://flagcdn.com/w320/yt.png" },
    { "code": "+52", "country": "Mexico", "country_code": "mx", "image": "https://flagcdn.com/w320/mx.png" },
    { "code": "+691", "country": "Micronesia", "country_code": "fm", "image": "https://flagcdn.com/w320/fm.png" },
    { "code": "+373", "country": "Moldova", "country_code": "md", "image": "https://flagcdn.com/w320/md.png" },
    { "code": "+377", "country": "Monaco", "country_code": "mc", "image": "https://flagcdn.com/w320/mc.png" },
    { "code": "+976", "country": "Mongolia", "country_code": "mn", "image": "https://flagcdn.com/w320/mn.png" },
    { "code": "+382", "country": "Montenegro", "country_code": "me", "image": "https://flagcdn.com/w320/me.png" },
    { "code": "+1664", "country": "Montserrat", "country_code": "ms", "image": "https://flagcdn.com/w320/ms.png" },
    { "code": "+212", "country": "Morocco", "country_code": "ma", "image": "https://flagcdn.com/w320/ma.png" },
    { "code": "+258", "country": "Mozambique", "country_code": "mz", "image": "https://flagcdn.com/w320/mz.png" },
    { "code": "+95", "country": "Myanmar", "country_code": "mm", "image": "https://flagcdn.com/w320/mm.png" },
    { "code": "+264", "country": "Namibia", "country_code": "na", "image": "https://flagcdn.com/w320/na.png" },
    { "code": "+674", "country": "Nauru", "country_code": "nr", "image": "https://flagcdn.com/w320/nr.png" },
    { "code": "+977", "country": "Nepal", "country_code": "np", "image": "https://flagcdn.com/w320/np.png" },
    { "code": "+31", "country": "Netherlands", "country_code": "nl", "image": "https://flagcdn.com/w320/nl.png" },
    { "code": "+599", "country": "Netherlands Antilles", "country_code": "an", "image": "https://flagcdn.com/w320/an.png" },
    { "code": "+687", "country": "New Caledonia", "country_code": "nc", "image": "https://flagcdn.com/w320/nc.png" },
    { "code": "+64", "country": "New Zealand", "country_code": "nz", "image": "https://flagcdn.com/w320/nz.png" },
    { "code": "+505", "country": "Nicaragua", "country_code": "ni", "image": "https://flagcdn.com/w320/ni.png" },
    { "code": "+227", "country": "Niger", "country_code": "ne", "image": "https://flagcdn.com/w320/ne.png" },
    { "code": "+234", "country": "Nigeria", "country_code": "ng", "image": "https://flagcdn.com/w320/ng.png" },
    { "code": "+683", "country": "Niue", "country_code": "nu", "image": "https://flagcdn.com/w320/nu.png" },
    { "code": "+850", "country": "North Korea", "country_code": "kp", "image": "https://flagcdn.com/w320/kp.png" },
    { "code": "+1670", "country": "Northern Mariana Islands", "country_code": "mp", "image": "https://flagcdn.com/w320/mp.png" },
    { "code": "+47", "country": "Norway", "country_code": "no", "image": "https://flagcdn.com/w320/no.png" },
    { "code": "+968", "country": "Oman", "country_code": "om", "image": "https://flagcdn.com/w320/om.png" },
    { "code": "+92", "country": "Pakistan", "country_code": "pk", "image": "https://flagcdn.com/w320/pk.png" },
    { "code": "+680", "country": "Palau", "country_code": "pw", "image": "https://flagcdn.com/w320/pw.png" },
    { "code": "+970", "country": "Palestine", "country_code": "ps", "image": "https://flagcdn.com/w320/ps.png" },
    { "code": "+507", "country": "Panama", "country_code": "pa", "image": "https://flagcdn.com/w320/pa.png" },
    { "code": "+675", "country": "Papua New Guinea", "country_code": "pg", "image": "https://flagcdn.com/w320/pg.png" },
    { "code": "+595", "country": "Paraguay", "country_code": "py", "image": "https://flagcdn.com/w320/py.png" },
    { "code": "+51", "country": "Peru", "country_code": "pe", "image": "https://flagcdn.com/w320/pe.png" },
    { "code": "+63", "country": "Philippines", "country_code": "ph", "image": "https://flagcdn.com/w320/ph.png" },
    { "code": "+64", "country": "Pitcairn Islands", "country_code": "pn", "image": "https://flagcdn.com/w320/pn.png" },
    { "code": "+48", "country": "Poland", "country_code": "pl", "image": "https://flagcdn.com/w320/pl.png" },
    { "code": "+351", "country": "Portugal", "country_code": "pt", "image": "https://flagcdn.com/w320/pt.png" },
    { "code": "+1939", "country": "Puerto Rico", "country_code": "pr", "image": "https://flagcdn.com/w320/pr.png" },
    { "code": "+974", "country": "Qatar", "country_code": "qa", "image": "https://flagcdn.com/w320/qa.png" },
    { "code": "+242", "country": "Réunion", "country_code": "re", "image": "https://flagcdn.com/w320/re.png" },
    { "code": "+40", "country": "Romania", "country_code": "ro", "image": "https://flagcdn.com/w320/ro.png" },
    { "code": "+7", "country": "Russia", "country_code": "ru", "image": "https://flagcdn.com/w320/ru.png" },
    { "code": "+250", "country": "Rwanda", "country_code": "rw", "image": "https://flagcdn.com/w320/rw.png" },
    { "code": "+685", "country": "Samoa", "country_code": "ws", "image": "https://flagcdn.com/w320/ws.png" },
    { "code": "+378", "country": "San Marino", "country_code": "sm", "image": "https://flagcdn.com/w320/sm.png" },
    { "code": "+239", "country": "São Tomé and Príncipe", "country_code": "st", "image": "https://flagcdn.com/w320/st.png" },
    { "code": "+966", "country": "Saudi Arabia", "country_code": "sa", "image": "https://flagcdn.com/w320/sa.png" },
    { "code": "+221", "country": "Senegal", "country_code": "sn", "image": "https://flagcdn.com/w320/sn.png" },
    { "code": "+381", "country": "Serbia", "country_code": "rs", "image": "https://flagcdn.com/w320/rs.png" },
    { "code": "+248", "country": "Seychelles", "country_code": "sc", "image": "https://flagcdn.com/w320/sc.png" },
    { "code": "+232", "country": "Sierra Leone", "country_code": "sl", "image": "https://flagcdn.com/w320/sl.png" },
    { "code": "+65", "country": "Singapore", "country_code": "sg", "image": "https://flagcdn.com/w320/sg.png" },
    { "code": "+721", "country": "Sint Maarten", "country_code": "sx", "image": "https://flagcdn.com/w320/sx.png" },
    { "code": "+421", "country": "Slovakia", "country_code": "sk", "image": "https://flagcdn.com/w320/sk.png" },
    { "code": "+386", "country": "Slovenia", "country_code": "si", "image": "https://flagcdn.com/w320/si.png" },
    { "code": "+677", "country": "Solomon Islands", "country_code": "sb", "image": "https://flagcdn.com/w320/sb.png" },
    { "code": "+252", "country": "Somalia", "country_code": "so", "image": "https://flagcdn.com/w320/so.png" },
    { "code": "+27", "country": "South Africa", "country_code": "za", "image": "https://flagcdn.com/w320/za.png" },
    { "code": "+82", "country": "South Korea", "country_code": "kr", "image": "https://flagcdn.com/w320/kr.png" },
    { "code": "+34", "country": "Spain", "country_code": "es", "image": "https://flagcdn.com/w320/es.png" },
    { "code": "+94", "country": "Sri Lanka", "country_code": "lk", "image": "https://flagcdn.com/w320/lk.png" },
    { "code": "+249", "country": "Sudan", "country_code": "sd", "image": "https://flagcdn.com/w320/sd.png" },
    { "code": "+597", "country": "Suriname", "country_code": "sr", "image": "https://flagcdn.com/w320/sr.png" },
    { "code": "+268", "country": "Swaziland", "country_code": "sz", "image": "https://flagcdn.com/w320/sz.png" },
    { "code": "+46", "country": "Sweden", "country_code": "se", "image": "https://flagcdn.com/w320/se.png" },
    { "code": "+41", "country": "Switzerland", "country_code": "ch", "image": "https://flagcdn.com/w320/ch.png" },
    { "code": "+963", "country": "Syria", "country_code": "sy", "image": "https://flagcdn.com/w320/sy.png" },
    { "code": "+886", "country": "Taiwan", "country_code": "tw", "image": "https://flagcdn.com/w320/tw.png" },
    { "code": "+992", "country": "Tajikistan", "country_code": "tj", "image": "https://flagcdn.com/w320/tj.png" },
    { "code": "+255", "country": "Tanzania", "country_code": "tz", "image": "https://flagcdn.com/w320/tz.png" },
    { "code": "+66", "country": "Thailand", "country_code": "th", "image": "https://flagcdn.com/w320/th.png" },
    { "code": "+670", "country": "Timor-Leste", "country_code": "tl", "image": "https://flagcdn.com/w320/tl.png" },
    { "code": "+228", "country": "Togo", "country_code": "tg", "image": "https://flagcdn.com/w320/tg.png" },
    { "code": "+690", "country": "Tokelau", "country_code": "tk", "image": "https://flagcdn.com/w320/tk.png" },
    { "code": "+676", "country": "Tonga", "country_code": "to", "image": "https://flagcdn.com/w320/to.png" },
    { "code": "+1868", "country": "Trinidad and Tobago", "country_code": "tt", "image": "https://flagcdn.com/w320/tt.png" },
    { "code": "+216", "country": "Tunisia", "country_code": "tn", "image": "https://flagcdn.com/w320/tn.png" },
    { "code": "+90", "country": "Turkey", "country_code": "tr", "image": "https://flagcdn.com/w320/tr.png" },
    { "code": "+993", "country": "Turkmenistan", "country_code": "tm", "image": "https://flagcdn.com/w320/tm.png" },
    { "code": "+688", "country": "Tuvalu", "country_code": "tv", "image": "https://flagcdn.com/w320/tv.png" },
    { "code": "+256", "country": "Uganda", "country_code": "ug", "image": "https://flagcdn.com/w320/ug.png" },
    { "code": "+380", "country": "Ukraine", "country_code": "ua", "image": "https://flagcdn.com/w320/ua.png" },
    { "code": "+971", "country": "United Arab Emirates", "country_code": "ae", "image": "https://flagcdn.com/w320/ae.png" },
    { "code": "+44", "country": "United Kingdom", "country_code": "gb", "image": "https://flagcdn.com/w320/gb.png" },
    { "code": "+1", "country": "United States", "country_code": "us", "image": "https://flagcdn.com/w320/us.png" },
    { "code": "+598", "country": "Uruguay", "country_code": "uy", "image": "https://flagcdn.com/w320/uy.png" },
    { "code": "+998", "country": "Uzbekistan", "country_code": "uz", "image": "https://flagcdn.com/w320/uz.png" },
    { "code": "+678", "country": "Vanuatu", "country_code": "vu", "image": "https://flagcdn.com/w320/vu.png" },
    { "code": "+58", "country": "Venezuela", "country_code": "ve", "image": "https://flagcdn.com/w320/ve.png" },
    { "code": "+681", "country": "Wallis and Futuna", "country_code": "wf", "image": "https://flagcdn.com/w320/wf.png" },
    { "code": "+967", "country": "Yemen", "country_code": "ye", "image": "https://flagcdn.com/w320/ye.png" },
    { "code": "+260", "country": "Zambia", "country_code": "zm", "image": "https://flagcdn.com/w320/zm.png" },
    { "code": "+263", "country": "Zimbabwe", "country_code": "zw", "image": "https://flagcdn.com/w320/zw.png" }
  ];
  
