import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';

const checkSlice = createSlice({
  name: 'check',
  initialState: initialState.twofactor,
  reducers: {
    check: (state) => {
      state.isLoading = true;
    },
    checkSuccess: (state, action) => {
      state.isLoading = false;
      state.twofactor = action.payload;
    },
    checkFail: (state, action) => {
      state.isLoading = false;
      state.twofactor = action.payload;
    },
  },
});

export const { check, checkSuccess } = checkSlice.actions;
export default checkSlice.reducer;
