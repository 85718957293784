import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { setScreen } from "../../redux/Reducers/navigationSlice";

import RHFRadioGroup from "../../components/forms/RHFRadioGroup";
import Textbox from "../../components/forms/RHFTextbox";
import Button from "../../components/common/Button";
import HeaderMobile from "../../components/layouts/HeaderMobile";

import useDetectLanguage from "../../hooks/useDetectLanguage";
import useFetchVisitorInfo from "../../hooks/useFetchVisitorInfo";
import useMobile from "../../hooks/useMobile";

import ReviewInProgressPg from "../../asset/image/request-review-pg.png";

const RequestReviewPage = () => {
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const isMobile = useMobile();

    const { t } = useTranslation();
    const { visitor } = useFetchVisitorInfo();
    useDetectLanguage(visitor);

    const methods = useForm({
        defaultValues: { reason: "", otherReason: "" },
    });

    const onSubmit = () => {
        setIsSubmitting(true);
        setTimeout(() => {
            dispatch(setScreen("reviewInProgress"));
            setIsSubmitting(false);
        }, 1000);
    };

    return (
        <div className="lg:h-screen lg:w-screen w-full flex flex-col items-center justify-center">
            {isMobile && (
                <div className="bg-white w-full">
                    <HeaderMobile />
                </div>
            )}
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(onSubmit)}
                    className="w-full h-full flex flex-col justify-between bg-white lg:shadow-lg lg:rounded lg:my-15 lg:w-[496px] lg:h-[690px] lg:max-w-2xl p-6"
                >
                    <h2 className="text-md font-semibold w-full">
                        {t("requestReview.title")}
                    </h2>
                    <img
                        src={ReviewInProgressPg}
                        alt="Review"
                        className="w-full h-[200px] object-cover rounded-lg"
                    />
                    <p className="text-gray-600 text-base my-4">
                        {t("requestReview.description")}
                    </p>

                    <h3 className="text-md font-semibold w-full py-2">
                        {t("requestReview.reasonTitle")}
                    </h3>
                    <RHFRadioGroup
                        name="reason"
                        options={[
                            {
                                label: t("requestReview.options.unsure"),
                                value: "unsure",
                            },
                            {
                                label: t("requestReview.options.unauthorized"),
                                value: "unauthorized",
                            },
                            {
                                label: t("requestReview.options.other"),
                                value: "other",
                            },
                        ]}
                    />

                    {methods.watch("reason") === "other" && (
                        <div className="mt-4">
                            <Textbox name="otherReason" placeholder="" />
                        </div>
                    )}

                    <div className="flex justify-end mt-4 space-x-2">
                        <Button
                            type="submit"
                            className="px-4 py-2 w-[100px] text-white bg-blue-500 rounded-md hover:bg-blue-600"
                            loading={isSubmitting}
                            disabled={isSubmitting}
                        >
                            {t("buttons.send")}
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default RequestReviewPage;
