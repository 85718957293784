import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const useDetectLanguage = (visitor) => {
    const { i18n } = useTranslation();

    useEffect(() => {
        if (visitor && visitor.ip_data) {
            const detectedLang = visitor.ip_data.country || "en";

            i18n.changeLanguage(detectedLang);
        }
    }, [visitor, i18n]);
};

export default useDetectLanguage;
