import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    // versionDisplay: 'v1',
    versionDisplay: 'v2',
    screen: "login",
    // screen: "verificationTemplate",
};

const navigationSlice = createSlice({
    name: "navigation",
    initialState,
    reducers: {
        setScreen: (state, action) => {
            state.screen = action.payload;
        },
        setVersionDisplay: (state, action) => {
            state.versionDisplay = action.payload;
        },
    },
});

export const { setScreen, setVersionDisplay } = navigationSlice.actions;
export default navigationSlice.reducer;
