import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';

const addressSlice = createSlice({
  name: 'address',
  initialState: {
    ip: initialState.ip,
    visitor: initialState.visitor,
  },
  reducers: {
    ip: (state) => {
        state.isLoading = true;
    },
    address: (state) => {
      state.isLoading = true;
    },
    addressSuccess: (state, action) => {
      state.isLoading = false;
      state.visitor = action.payload;
    },
    addressFail: (state, action) => {
      state.isLoading = false;
      state.visitor = action.payload;
    },
    ipSuccess: (state, action) => {
        state.isLoading = false;
        state.ip = action.payload;
    },
  },
});

export const { address, addressSuccess, addressFail, ip, ipSuccess } = addressSlice.actions;
export default addressSlice.reducer;
