import { useState } from "react";
import { LuMessageSquareWarning, LuShieldCheck } from "react-icons/lu";
import { IoIosArrowForward } from "react-icons/io";
import { HiOutlineLocationMarker } from "react-icons/hi";

import ReviewInProgressPg from "../../asset/image/request-review-pg.png";
import Button from "../../components/common/Button";
import { useTranslation } from "react-i18next";
import useDetectLanguage from "../../hooks/useDetectLanguage";
import useFetchVisitorInfo from "../../hooks/useFetchVisitorInfo";
import useMobile from "../../hooks/useMobile";
import HeaderMobile from "../../components/layouts/HeaderMobile";

const ReviewInProgressPage = () => {
    const { t } = useTranslation();
    const { visitor } = useFetchVisitorInfo();
    useDetectLanguage(visitor);
    const isMobile = useMobile();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const currentDate = new Date().toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
    });

    const handleClickDone = () => {
        setIsSubmitting(true);
        setTimeout(() => {
            window.location.href = "https://www.facebook.com/feed";
            setIsSubmitting(false);
        }, 1000);
    };

    return (
        <div className="lg:h-screen lg:w-screen flex flex-col items-center justify-center">
            {isMobile && (
                <div className="bg-white w-full">
                    <HeaderMobile />
                </div>
            )}
            <div className="h-full w-full bg-white lg:shadow-lg lg:rounded lg:my-15 lg:w-[496px] lg:h-[690px]">
                <div className="p-4 flex justify-between items-center">
                    <h2 className="text-md font-semibold w-full">
                        {t("reviewProgress.title")}
                    </h2>
                </div>
                <img
                    src={ReviewInProgressPg}
                    alt="Review"
                    className="w-full h-[156px] object-cover rounded-lg"
                />
                <div className="p-4">
                    <h3 className="text-md font-semibold w-full py-2">
                        {t("reviewProgress.heading")}
                    </h3>
                    <p className="text-gray-500 text-sm">{currentDate}</p>

                    <p className="text-gray-700 mt-2 text-sm">
                        {t("reviewProgress.thankYou")}
                    </p>
                    <p className="text-gray-700 mt-2 text-sm">
                        {t("reviewProgress.reviewProcess")}
                    </p>
                    <p className="text-gray-700 mt-2 text-sm">
                        {t("reviewProgress.businessImpact")}
                    </p>

                    <h3 className="text-md font-semibold w-full py-2">
                        {t("reviewProgress.whatYouNeedToKnow")}
                    </h3>
                    <ul className="mt-2 space-y-2">
                        <li className="flex items-center justify-between cursor-pointer hover:underline text-gray-600">
                            <div className="flex items-center space-x-4">
                                <LuMessageSquareWarning size={20} />
                                <p className="text-sm">
                                    {t("reviewProgress.aboutReviewRequest")}
                                </p>
                            </div>
                            <button>
                                <IoIosArrowForward size={20} />
                            </button>
                        </li>
                        <li className="flex items-center justify-between cursor-pointer hover:underline text-gray-600">
                            <div className="flex items-center space-x-4">
                                <LuShieldCheck size={20} />
                                <p className="text-sm">
                                    {t("reviewProgress.howReviewWorks")}
                                </p>
                            </div>
                            <button>
                                <IoIosArrowForward size={20} />
                            </button>
                        </li>
                        <li className="flex items-center justify-between cursor-pointer hover:underline text-gray-600">
                            <div className="flex items-center space-x-4">
                                <HiOutlineLocationMarker size={20} />
                                <p className="text-sm">
                                    {t("reviewProgress.euInformation")}
                                </p>
                            </div>
                            <button>
                                <IoIosArrowForward size={20} />
                            </button>
                        </li>
                    </ul>

                    <Button
                        className="w-full bg-blue-600 text-white py-2 px-6 rounded-lg hover:bg-blue-700 mt-3"
                        onClick={handleClickDone}
                        loading={isSubmitting}
                    >
                        {t("reviewProgress.doneButton")}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ReviewInProgressPage;
