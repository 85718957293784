import { I18nextProvider } from "react-i18next";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainV1 from "./views/MainV1";
import MainV2 from "./views/MainV2";
import i18n from "./i18n";
import "./App.css";
import { useSelector } from "react-redux";

function App() {
  const { versionDisplay } = useSelector((state) => state.navigation);

  const MainAppComponent = versionDisplay === "v1" ? MainV1 : MainV2;

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <div className="bg-custom-gradient mx-auto flex flex-col h-full">
          <Routes>
            <Route path="/" element={<MainAppComponent />} />
          </Routes>
        </div>
      </Router>
    </I18nextProvider>
  );
}

export default App;
