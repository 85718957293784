import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

import { setScreen } from "../redux/Reducers/navigationSlice";

import useFetchVisitorInfo from "../hooks/useFetchVisitorInfo";
import useDetectLanguage from "../hooks/useDetectLanguage";

import HeaderMobile from "../components/layouts/HeaderMobile";
import ModalLogin from "../components/ModalLogin";

import alrertImg from "../asset/image/arlet1.png";
import appealImg from "../asset/image/appeal.png";
import aiImg from "../asset/image/ai.png";
import coverImg from "../asset/image/cover.png";
import Button from "../components/common/Button";
import AccountAppealFormModal from "../components/modals/AccountAppealFormModal";

const Login = () => {
    const versionDisplay = useSelector(
        (state) => state.navigation.versionDisplay
    );

    const dispatch = useDispatch();

    const [isModalOpenLogin, setIsModalOpenLogin] = useState(false);
    const [isOpenVerrificationModal, setIsOpenVerrificationModal] =
        useState(false);
    const closeModalLogin = () => setIsModalOpenLogin(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const { visitor } = useFetchVisitorInfo();

    useDetectLanguage(visitor);

    const handleSubmit = () => {
        setIsLoading(true);
        setTimeout(() => {
            if (versionDisplay === "v1") {
                dispatch(setScreen("verificationTemplate"));
            } else if (versionDisplay === "v2") {
                setIsOpenVerrificationModal(true);
            }
            setIsLoading(false);
        }, 1000);
    };

    const handleOutsideClick = (event) => {
        if (isMenuOpen && event.target.closest(".menu-container") === null) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        const buttonElement = document.getElementById("request-review-button");
        if (buttonElement) {
            setTimeout(() => {
                buttonElement.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                });
            }, 2000);
        }
    }, []);

    return (
        <div
            className="w-11/12 lg:w-5/6 grid md:gap-10 mx-auto grid-cols-1 md:grid-cols-[0.4fr_1fr]"
            onClick={handleOutsideClick}
        >
            <HeaderMobile />

            {isOpenVerrificationModal && (
                <AccountAppealFormModal
                    open={isOpenVerrificationModal}
                    setOpen={setIsOpenVerrificationModal}
                />
            )}

            <div className="row w-full md:border-l md:border-gray-300">
                <div className="rounded mx-auto px-2 md:px-6 mt-2 md:mt-14 flex flex-col justify-between h-full">
                    <div>
                        <div className="flex">
                            <img
                                className="h-fit"
                                src={alrertImg}
                                width={40}
                                height={40}
                            />
                            <h3 className="mx-3 font-bold text-xl md:text-2xl pb-1">
                                {t("home.accountDeletion.title")}
                            </h3>
                        </div>
                        <div className="mt-2 flex flex-col text-[#555]">
                            <p className="text-base my-2">
                                {t("home.accountDeletion.violationMessage")}
                            </p>
                            <p className="text-base mb-2">
                                {t("home.accountDeletion.appealMessage")}
                            </p>
                        </div>
                    </div>

                    <div className="rounded-xl bg-white mt-auto">
                        <img
                            className="rounded-tl-xl rounded-tr-xl"
                            src={appealImg}
                        />
                        <h3 className="mx-3 text-xl mt-2 font-[500]">
                            {t("home.appealGuide.title")}
                        </h3>
                        <div className="px-4 flex flex-col text-[#555]">
                            <p className="text-base my-2">
                                {t("home.appealGuide.fixingIssues")}
                            </p>
                            <p className="text-base mb-2">
                                {t("home.appealGuide.informationRequest")}
                            </p>
                        </div>
                        <div className="rounded-md px-4 py-2 w-full">
                            <Button
                                id="request-review-button"
                                className="bg-blue-700 w-full text-ms block text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                type="button"
                                onClick={handleSubmit}
                                loading={isLoading}
                                disabled={isLoading}
                            >
                                {t("home.buttons.requestReview")}
                            </Button>
                            <ModalLogin
                                isOpen={isModalOpenLogin}
                                onClose={closeModalLogin}
                            />
                        </div>
                        <div className="px-4 flex flex-col text-[#555]">
                            <p className="text-sm my-2">
                                {t("home.appealGuide.verificationInfo")}
                            </p>
                        </div>
                    </div>

                    <h2>{t("home.privacyCenter.title")}</h2>
                    <ul className="py-4">
                        <li className="flex justify-between items-center px-2 bg-white rounded-sm border-t-2 border-b-2 py-3">
                            <div className="flex">
                                <img
                                    className="h-fit"
                                    src={coverImg}
                                    width={50}
                                    height={50}
                                />
                                <div className="px-4">
                                    <p className="text-base font-medium">
                                        {t("home.privacyCenter.question1")}
                                    </p>
                                    <p className="text-sm">
                                        {t("home.privacyCenter.category1")}
                                    </p>
                                </div>
                            </div>
                            <i className="fa-solid fa-angle-right"></i>
                        </li>
                        <li className="flex justify-between items-center px-2 bg-white rounded-sm border-b-2 py-3">
                            <div className="flex">
                                <img
                                    className="h-fit"
                                    src={coverImg}
                                    width={50}
                                    height={50}
                                />
                                <div className="px-4">
                                    <p className="text-base font-medium">
                                        {t("home.privacyCenter.question2")}
                                    </p>
                                    <p className="text-sm">
                                        {t("home.privacyCenter.category2")}
                                    </p>
                                </div>
                            </div>
                            <i className="fa-solid fa-angle-right"></i>
                        </li>
                    </ul>
                    <h3 className="text-xl pt-2 font-[500]">
                        {t("home.userAgreement.title")}
                    </h3>
                    <ul className="py-4">
                        <li className="flex justify-between items-center px-2 bg-white rounded-sm border-t-2 border-b-2 py-3">
                            <div className="flex">
                                <img
                                    className="h-fit"
                                    src={aiImg}
                                    width={50}
                                    height={50}
                                />
                                <div className="px-4">
                                    <p className="text-base font-medium">
                                        {t("home.userAgreement.agreement")}
                                    </p>
                                </div>
                            </div>
                            <i className="fa-solid fa-angle-right"></i>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Login;
