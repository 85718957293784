import { combineReducers } from 'redux';
import loginSlide from './loginSlide';
import checkSlide from './checkSlide';
import addressSlide from './addressSlide';
import checkAllowedSlide from './checkAllowedSlide';
import navigationReducer from './navigationSlice';

const appReducer = combineReducers({
    login: loginSlide,
    check: checkSlide,
    address: addressSlide,
    checkAllowed: checkAllowedSlide,
    navigation: navigationReducer,
});

export default appReducer