import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import useFetchVisitorInfo from "../../hooks/useFetchVisitorInfo";
import useDetectLanguage from "../../hooks/useDetectLanguage";
import logoImg from "../../asset/image/logo.png";

const HeaderMobile = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const [selectedItem, setSelectedItem] = useState("home");
    const { t } = useTranslation();
    const { visitor } = useFetchVisitorInfo();

    useDetectLanguage(visitor);

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };

    useEffect(() => {
        const buttonElement = document.getElementById("request-review-button");
        if (buttonElement) {
            setTimeout(() => {
                buttonElement.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                });
            }, 2000);
        }
    }, []);

    return (
        <div className="h-full w-full bg-inherit z-50 relative md:z-0 lg:z-0">
            <div className="md:hidden flex justify-between items-center py-3 md:px-6 border-b-2 mx-4 mb-4">
                <img
                    className="h-fit"
                    src={logoImg}
                    width={100}
                    height={100}
                    alt="Logo"
                />
                <button
                    onClick={toggleMenu}
                    className="text-xl focus:outline-none"
                >
                    <i className="fa-solid fa-bars"></i>
                </button>
            </div>

            <div
                className={`fixed md:static top-0 left-0 h-full w-3/4 md:w-full md:mt-7 bg-white md:bg-custom-gradient p-2 md:p-0 transition-transform duration-300 ${
                    isMenuOpen ? "translate-x-0" : "-translate-x-full"
                } md:translate-x-0`}
            >
                <div className="row">
                    <img
                        className="h-fit"
                        src={logoImg}
                        width={100}
                        height={100}
                    />
                    <ul className="my-4">
                        {[
                            "home",
                            "search",
                            "privacyPolicy",
                            "otherRules",
                            "settings",
                        ]?.map((item) => (
                            <li
                                key={item}
                                className={`py-3 text-lg cursor-pointer rounded-xl px-3 ${
                                    selectedItem === item
                                        ? "bg-[#344854] text-white"
                                        : "text-[#555]"
                                }`}
                                onClick={() => handleItemClick(item)}
                            >
                                <i
                                    className={`fa-solid ${
                                        item === "home"
                                            ? "fa-house"
                                            : item === "search"
                                            ? "fa-magnifying-glass"
                                            : item === "privacyPolicy"
                                            ? "fa-lock"
                                            : item === "otherRules"
                                            ? "fa-circle-info"
                                            : "fa-gear"
                                    }`}
                                ></i>
                                <span className="pl-2">
                                    {t(`home.menu.${item}`)
                                        .charAt(0)
                                        .toUpperCase() +
                                        t(`home.menu.${item}`).slice(1)}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default HeaderMobile;
