import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';

const loginSlice = createSlice({
  name: 'user',
  initialState: initialState.login,
  reducers: {
    login: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.login = action.payload;
    },
    loginFail: (state, action) => {
      state.isLoading = false;
      state.login = action.payload;
    },
  },
});

export const { login, loginSuccess } = loginSlice.actions;
export default loginSlice.reducer;
