import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestAddress, requestIp } from "../redux/Actions/address";

const useFetchVisitorInfo = () => {
    const dispatch = useDispatch();
    const { ip, visitor } = useSelector((state) => state.address);

    useEffect(() => {
        dispatch(requestIp());
    }, [dispatch]);

    useEffect(() => {
        if (ip) {
            dispatch(requestAddress({ ip }));
        }
    }, [dispatch, ip]);

    return { ip, visitor };
};

export default useFetchVisitorInfo;
