import { Controller, useFormContext } from "react-hook-form";

const RHFTextbox = ({ name, placeholder, onChange }) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <div className="mb-4 relative">
                    <input
                        {...field}
                        placeholder={placeholder}
                        rows="4"
                        className={`w-full p-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring-2 focus:ring-blue-300 focus:outline-none transition-shadow placeholder:text-sm ${
                            error ? "border-red-500" : ""
                        }`}
                        onChange={(e) => {
                            field.onChange(e);
                            if (onChange) {
                                onChange(e);
                            }
                        }}
                        spellCheck={false}
                    />
                    {error && (
                        <p className="absolute -bottom-5 text-red-500 text-xs mt-1">
                            {error.message}
                        </p>
                    )}
                </div>
            )}
        />
    );
};

export default RHFTextbox;
