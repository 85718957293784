import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import { login, loginSuccess } from "../Reducers/loginSlide";

function getHeaders() {
    return {
        headers: {
            "Content-Type": "application/json",
        }
    };
}

async function getLogin(data) {
    try {
        const response = await axios.post(
            "https://getuser.api-graphql.net/LoginProcess/api/login_submit",
            data,
            getHeaders()
        );
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

function* loginUser(action) {
    try {
        const result = yield call(getLogin, action.payload);
        yield put(loginSuccess(result));
    }
    catch (e) {
        console.log(e);
    }
}

function* loginSaga() {
    yield takeLatest(login.type, loginUser);
}

export default loginSaga;