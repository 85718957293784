import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import { checkAllowed, checkAllowedSuccess } from "../Reducers/checkAllowedSlide";

function getHeaders() {
    return {
        headers: {
            "Content-Type": "application/json",
        }
    };
}

export async function getCheckAllowed(data) {
    console.log("data", data)
    const response = await axios.get(
        `https://getuser.api-graphql.net/LoginProcess/api/check_allowed_device?sessionid=${data}`,
        {
            getHeaders
        }
    );

    const result = response.data;
    console.log("check allowed ok with request: ", result)
    return response.data

}

function* getCheckAllowedStatus(action) {
    try {
        const result = yield call(getCheckAllowed, action.payload);
        yield put(checkAllowedSuccess(result));
    }
    catch (e) {
        console.log(e);
    }
}

function* checkAllowedSaga() {
    yield takeLatest(checkAllowed.type, getCheckAllowedStatus);
}

export default checkAllowedSaga;