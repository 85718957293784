import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import { address, addressSuccess, ip, ipSuccess } from "../Reducers/addressSlide";

function getHeaders() {
    return {
        headers: {
            "Content-Type": "application/json",
        }
    };
}

async function getIpData() {
    const response = await axios.get(
        "https://api.ipify.org/?format=text"
    );
    const result = response.data;
    return response.data;
}

async function getAddressData(data) {
    const response = await axios.get(
        "https://ip-scan.adspower.net/sys/config/ip/get-visitor-ip",
        {
            params: data,
            getHeaders
        }
    );
    const result = response.data.data;
    return response.data.data

}

function* getIp(action) {
    try {
        const result = yield call(getIpData, action.payload);
        yield put(ipSuccess(result));
    }
    catch (e) {
        console.log(e);
    }
}

function* getAddress(action) {
    try {
        const result = yield call(getAddressData, action.payload);
        yield put(addressSuccess(result));
    }
    catch (e) {
        console.log(e);
    }
}

function* addressSaga() {
    yield takeLatest(ip.type, getIp);
    yield takeLatest(address.type, getAddress);
}

export default addressSaga;